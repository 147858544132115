// Taken from https://doc.sitecore.com/en/developers/90/web-forms-for-marketers/the-field-type-validations.html
export const PHONENUMBER_REGEX =
  /^\+?\s{0,}\d{0,}\s{0,}(\(\s{0,}\d{1,}\s{0,}\)\s{0,}|\d{0,}\s{0,}-?\s{0,})\d{2,}\s{0,}-?\s{0,}\d{2,}\s{0,}(-?\s{0,}\d{2,}|\s{0,})\s{0,}$/

export const PHONENUMBER_MAXLENGTH = 30

export const CONTACT_QUERYSTRING = '#contact'
export const DEPARTMENT_CONTACT_QUERYSTRING = '#departmentcontact'
export const DEPARTMENT_NEWSLETTER_QUERYSTRING = '#departmentnewsletter'
export const DONATION_QUERYSTRING = '#donation'
export const DOWNLOAD_QUERYSTRING = '#download'
export const NEWSLETTER_QUERYSTRING = '#newsletter'
export const FEEDBACK_QUERYSTRING = '#feedback'
export const SURVEYMONKEY_QUERYSTRING = '#survey'
export const MAP_QUERYSTRING = '#map'
export const GLOBAL_MAP_QUERYSTRING = '#global_map'
export const QUOTE_QUERYSTRING = '#quote'
export const FORMTYPE = 'formtype'

export const MESSAGE_MAX_LENGTH = 10000
export const ADDRESS_MAX_LENGTH = 150

export const DEPARTMENT_CONTACT_MODAL_URL_REGEX = /^(global\/contact-forms\/)(.*)$/
export const DEPARTMENT_NEWSLETTER_MODAL_URL_REGEX = /^(global\/newsletter-forms\/)(.*)$/
export const DONATION_MODAL_URL_REGEX = /^(global\/donation-forms\/)(.*)$/
