import { useRouter } from 'next/router'
import { useEffect } from 'react'
import {
  CONTACT_QUERYSTRING,
  DEPARTMENT_CONTACT_QUERYSTRING,
  DEPARTMENT_NEWSLETTER_QUERYSTRING,
  DONATION_QUERYSTRING,
  FEEDBACK_QUERYSTRING,
  FORMTYPE,
  MAP_QUERYSTRING,
  GLOBAL_MAP_QUERYSTRING,
  NEWSLETTER_QUERYSTRING,
  SURVEYMONKEY_QUERYSTRING,
} from '@/components/Forms/constants'
import {
  showContactModal,
  showDepartmentContactModal,
  showDepartmentNewsletterModal,
  showDonationModal,
  showFeedbackModal,
  showMapModal,
  showGlobalMapModal,
  showNewsletterModal,
  showSurveyMonkeyModal,
} from '@/lib/apollo/useApollo'

export const setModalsStateBasedOnUrl = (url: string) => {
  showContactModal(url.includes(CONTACT_QUERYSTRING))
  showNewsletterModal(url.includes(NEWSLETTER_QUERYSTRING))
  showFeedbackModal(url.includes(FEEDBACK_QUERYSTRING))
  showSurveyMonkeyModal(url.includes(SURVEYMONKEY_QUERYSTRING))
  showMapModal(url.includes(MAP_QUERYSTRING))
  showGlobalMapModal(url.includes(GLOBAL_MAP_QUERYSTRING))
}

export const useQuerystringModal = () => {
  const router = useRouter()
  const hideDepartmentModals = () => {
    showDepartmentContactModal(undefined)
    showDepartmentNewsletterModal(undefined)
    showDonationModal(undefined)
  }

  useEffect(() => {
    const handleHashChange = (url: string) => {
      if (url) {
        setModalsStateBasedOnUrl(url)

        if (url.includes(DEPARTMENT_CONTACT_QUERYSTRING)) {
          const formType = localStorage.getItem(FORMTYPE)
          showDepartmentContactModal(formType)
          return
        }
        if (url.includes(DONATION_QUERYSTRING)) {
          const formType = localStorage.getItem(FORMTYPE)
          showDonationModal(formType)
          return
        }
        if (url.includes(DEPARTMENT_NEWSLETTER_QUERYSTRING)) {
          const formType = localStorage.getItem(FORMTYPE)
          showDepartmentNewsletterModal(formType)
          return
        }
      }

      hideDepartmentModals()
    }

    router.events.on('hashChangeComplete', handleHashChange)

    return () => {
      router.events.off('hashChangeComplete', handleHashChange)
    }
  }, [router.events, router.asPath])
}
